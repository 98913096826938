import React from "react";
import { TextBlockWrapper, TextBlockContent } from "./styled";
import { GlobalInnerContent } from "../../../assets/styles/commonStyles";

const TextBlock = (props) => {
	const { full = true, bgColor, color, align, children } = props;
	return (
		<TextBlockWrapper bgColor={bgColor} align={align}>
			<GlobalInnerContent>
				<TextBlockContent full={full} align={align} color={color}>
					{children}
				</TextBlockContent>
			</GlobalInnerContent>
		</TextBlockWrapper>
	);
};

export default TextBlock;
