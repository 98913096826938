import styled from "styled-components";
import { mediaQueryMax } from "../../../assets/styles/size";

export const VideoYTWrapper = styled.section``;

export const VideoPreview = styled.div`
	cursor: pointer;
	position: relative;
	overflow: hidden;
	span {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 40px;
		@media ${mediaQueryMax.lg0} {
			width: 30px;
		}
		svg {
			width: 100%;
			height: auto;
		}
	}
	figure {
		width: 100%;
		margin: 0;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
`;
