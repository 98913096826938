import React from "react";
import { connect } from "react-redux";
import { VideoYTWrapper, VideoPreview } from "./styled";
import { GlobalInnerContent } from "../../../assets/styles/commonStyles";
import { appUIPopupVideoShow } from "../../../redux/app/actions";
import { ReactSVG } from "react-svg";
import IconPlay from "../../../assets/gfx/svgicons/icon-play.svg";

const VideoYT = ({ imgPreview, videoId, appUIPopupVideoShow }) => {
	return (
		<VideoYTWrapper>
			<GlobalInnerContent>
				<VideoPreview onClick={() => appUIPopupVideoShow(videoId)}>
					<ReactSVG src={IconPlay} wrapper="span" />
					<figure>
						<img src={imgPreview} alt="" />
					</figure>
				</VideoPreview>
			</GlobalInnerContent>
		</VideoYTWrapper>
	);
};

const mapDispatchToProps = {
	appUIPopupVideoShow,
};

export default connect(null, mapDispatchToProps)(VideoYT);
