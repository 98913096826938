import styled, { css } from "styled-components";
import { mediaQueryMax, sizes } from "../../../assets/styles/size";
import { GlobalInnerContent } from "../../../assets/styles/commonStyles";

export const TextBlockWrapper = styled.section`
	${(props) => props.bgColor && `background-color: ${props.bgColor}`};
	${(props) =>
		props.align === "center"
			? css`
					padding: 100px 0;
					@media ${mediaQueryMax.lg0} {
						padding: 50px 0;
					}
			  `
			: css`
					padding: 50px 0 70px;
					@media ${mediaQueryMax.lg0} {
						padding: 40px 0 50px;
					}
			  `};
	${GlobalInnerContent} {
		margin-bottom: 0;
	}
`;

export const TextBlockContent = styled.div`
	overflow: hidden;
	${(props) => props.color && `color: ${props.color}`};
	${(props) =>
		props.align === "center" &&
		css`
			text-align: center;
			max-width: ${sizes.md};
			margin: auto;
		`};
	${(props) => !props.full && "padding-right:20%;"}
	@media ${mediaQueryMax.lg0} {
		padding-right: 0;
	}
	p {
		padding-top: 20px;
		font-size: 18px;
		@media ${mediaQueryMax.lg0} {
			font-size: 16px;
		}
	}
`;
