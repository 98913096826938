import styled, { css } from "styled-components";
import { colors } from "../../../assets/styles/colors";
import { mediaQueryMax } from "../../../assets/styles/size";
import { GlobalInnerContent } from "../../../assets/styles/commonStyles";

export const FullImageWrapper = styled.section`
	height: 450px;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	@media ${mediaQueryMax.lg0} {
		align-items: flex-end;
	}
  figure {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
		z-index: 0;
    margin: 0;
    img {
      width: 100%;
      height: auto;
      //height: 100%;
      object-fit: cover;
    }
    ${(props) =>
			props.imgMobile &&
			css`
		img {
			&.desktop {
				display: block;
			}
			&.mobile {
				display: none;
			}
		}
		@media ${mediaQueryMax.lg0} {
			img {
				&.desktop {
					display: none;
				}
				&.mobile {
					display: block;
				}
			}
		}
	}
	`};

	${GlobalInnerContent} {
		position: relative;
		z-index: 2;
		@media ${mediaQueryMax.lg0} {
			margin-bottom: 30px;
		}
	}
`;

export const Gradient = styled.div`
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: linear-gradient(360deg, ${colors.blue} 11%, rgba(0, 39, 74, 0) 62.85%);
	@media ${mediaQueryMax.lg0} {
		display: block;
	}
`;
