import React from "react";
import { FullImageWrapper, Gradient } from "./styled";
import { GlobalInnerContent } from "../../../assets/styles/commonStyles";

const ImageFull = (props) => {
	const { imgDesktop, imgMobile, alt = "", gradient = false, children } = props;
	return (
		<FullImageWrapper imgMobile={imgMobile}>
			<figure>
				<img className="desktop" src={imgDesktop} alt={alt} />
				{imgMobile && <img className="mobile" src={imgMobile} alt={alt} />}
			</figure>
			{gradient && <Gradient />}

			{children && <GlobalInnerContent>{children}</GlobalInnerContent>}
		</FullImageWrapper>
	);
};

export default ImageFull;
