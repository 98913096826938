import React, { useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Footer from "../components/footer";
import BodyClassName from "react-body-classname";
import { connect } from "react-redux";
import {
	appRedirect,
	appUIPopupLoginShow,
	appUIPopupTypeformShow,
	anunciosHomeInit,
	anunciosHomeGet,
	appUIErrorsShow,
} from "../redux/app/actions";
import { useIntl, navigate } from "gatsby-plugin-intl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tools from "../classes/tools";
import SliderTestimonials from "../components/common/SliderTestimonials";
import SpecialBullets from "../components/common/SpecialBullets";
import ImageFull from "../components/common/ImageFull";
import TextBlock from "../components/common/TextBlock";
import ImageTwoCol from "../components/common/ImageTwoCol";
import VideoYT from "../components/common/VideoYT";
import SpacerCustom from "../components/common/SpacerCustom";
import { StyledButton } from "../assets/styles/commonStyles";
import ImageList from "../components/common/ImageList";

const IndexPage = ({
	authResolved,
	loggedIn,
	usuario,
	appUIPopupTypeformShow,
	appUIPopupLoginShow,
	appRedirect,
	home_areas_sliders,
	anunciosHomeInit,
	anunciosHomeGet,
	bootLoaded,
	s,
}) => {
	const intl = useIntl();

	const bulletsItems = [
		{
			title: intl?.formatMessage({ id: "landing.steps.one" }),
			text: "",
		},
		{
			title: intl?.formatMessage({ id: "landing.steps.two" }),
			text: "",
		},
		{
			title: intl?.formatMessage({ id: "landing.steps.three" }),
			text: "",
		},
	];
	const bulletsItems2 = [
		{
			title: intl?.formatMessage({ id: "landing.stepsIcons.one" }),
			text: "",
			icon: "/img/icons/icon-money.svg",
		},
		{
			title: intl?.formatMessage({ id: "landing.stepsIcons.two" }),
			text: "",
			icon: "/img/icons/icon-board.svg",
		},
		{
			title: intl?.formatMessage({ id: "landing.stepsIcons.three" }),
			text: "",
			icon: "/img/icons/icon-security.svg",
		},
	];
	const testimonialsSliderDetails = [
		{
			icon: "?",
			name: intl?.formatMessage({ id: "landing.testimonials.one.title" }),
			text: intl?.formatMessage({ id: "landing.testimonials.one.text" }),
		},
		{
			icon: "?",
			name: intl?.formatMessage({ id: "landing.testimonials.two.title" }),
			text: intl?.formatMessage({ id: "landing.testimonials.two.text" }),
		},
		{
			icon: "?",
			name: intl?.formatMessage({ id: "landing.testimonials.three.title" }),
			text: intl?.formatMessage({ id: "landing.testimonials.three.text" }),
		},
		{
			icon: "?",
			name: intl?.formatMessage({ id: "landing.testimonials.four.title" }),
			text: intl?.formatMessage({ id: "landing.testimonials.four.text" }),
		},
	];
	const imageItems = [
		{
			img: "/img/logos/logo-redbull.png",
			url: "https://www.redbull.com/es-es/surf-nace-share-your-board-itw",
		},
		{
			img: "/img/logos/logo-surferule.png",
			url: "https://www.surferrule.com/share-your-board-plataforma-colaborativa/",
		},
		{
			img: "/img/logos/logo-surf3o.png",
			url: "https://www.surf30.net/2020/07/share-your-board-lanza-su-web-para.html",
		},
		{
			img: "/img/logos/logo-3sesenta.png",
			url: "https://www.3sesenta.com/share-your-board-marketplace-de-tablas-de-surf/",
		},
		{
			img: "/img/logos/logo-as.png",
			url: "https://as.com/deportes_accion/2021/06/15/surf/1623749521_576212.html",
		},
	];

	const crearAnuncio = (e) => {
		e.preventDefault();
		if (authResolved && loggedIn) appRedirect({ to: usuario.completo ? "/crearanuncio" : "/editarcuenta" });
		else appUIPopupLoginShow();
	};

	useEffect(() => {
		if (bootLoaded) {
			anunciosHomeInit();
			home_areas_sliders.forEach((a) => {
				anunciosHomeGet(intl.locale, { area: a.id, mode: "area" });
			});
		}
	}, [bootLoaded]);

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="home">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s?.home?.title)} />
					<main className="page">
						<section>
							<ImageFull
								imgDesktop="/img/bg/landing-header-desktop.png"
								imgMobile="/img/bg/landing-header-mobile.png"
								alt="Así es como puedes ganar dinero con tus tablas de surf en tres sencillos pasos"
								gradient={true}
							/>

							<TextBlock full={false}>
								<h2>{intl?.formatMessage({ id: "landing.main.title" })}</h2>
								<p dangerouslySetInnerHTML={{ __html: intl?.formatMessage({ id: "landing.main.text" }) }} />
							</TextBlock>

							{/* BULLETS */}
							<SpecialBullets items={bulletsItems} upperCase={false} />

							<TextBlock>
								<StyledButton center={true}>
									<button onClick={crearAnuncio}>{intl?.formatMessage({ id: "landing.steps.button" })}</button>
								</StyledButton>
							</TextBlock>

							<ImageTwoCol
								full={true}
								position="right"
								imgDesktop={"/img/ad2-desktop.png"}
								imgMobile={"/img/ad2-mobile.png"}
								title={intl?.formatMessage({ id: "landing.twocol.title" })}
								text={intl?.formatMessage({ id: "landing.twocol.text" })}
								linkText={intl?.formatMessage({ id: "landing.twocol.button" })}
								external={false}
								action={crearAnuncio}
							/>

							<TextBlock bgColor="#000" color="#fff" align="center">
								<h3>{intl?.formatMessage({ id: "landing.banner" })}</h3>
							</TextBlock>

							<SpacerCustom height="100" />

							<VideoYT imgPreview="/img/video-preview.png" videoId="5lKpjcvE3Lk" />

							{/* BULLETS */}
							<SpecialBullets items={bulletsItems2} upperCase={false} />

							{/* TESTIMONIALS */}
							<SliderTestimonials items={testimonialsSliderDetails} />

							{/* LOGOS */}
							<ImageList items={imageItems} />
						</section>
					</main>
					<Footer />
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { resolved: authResolved, loggedIn, usuario } = state.auth;
	const { geo, anunciosHome } = state.app;
	const { home_areas_banners, home_areas_sliders, loaded: bootLoaded, areas } = state.app.boot;
	return {
		authResolved,
		loggedIn,
		usuario,
		geo,
		anunciosHome,
		home_areas_banners,
		home_areas_sliders,
		bootLoaded,
		areas,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appRedirect,
	appUIPopupLoginShow,
	appUIPopupTypeformShow,
	appUIErrorsShow,
	anunciosHomeInit,
	anunciosHomeGet,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
