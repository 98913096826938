import React from "react";
import styled from "styled-components";
import { mediaQueryMax } from "../../../assets/styles/size";

const Spacer = styled.div`
	display: block;
	overflow: hidden;
	width: 100%;
	height: ${(props) => props.height}px;
	@media ${mediaQueryMax.lg0} {
		height: ${(props) => props.height / 2}px;
	}
`;

const SpacerCustom = ({ height }) => {
	return <Spacer height={height} />;
};

export default SpacerCustom;
